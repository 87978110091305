import React from 'react';
import ReactDOM from 'react-dom';

import { Switch, Route, BrowserRouter } from 'react-router-dom';

//import App from './screen';
import App from './screen/app';
import AppTwo from './screen/app-two';

import './scss/style.scss';

const Router = () => (
	<BrowserRouter>
		<Switch>
			<Route path="/" exact component={App}/>
			<Route path="/app-2nd" exact component={AppTwo}/>
		</Switch>
	</BrowserRouter>
);

ReactDOM.render(<Router/>, document.getElementById('root-react'));
